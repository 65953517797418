var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mb-5"},[_c('CCol',{attrs:{"lg":"3"}},[_c('TAside',[_c('CListGroup',[_c('CListGroupItem',{staticClass:"cursor-pointer d-fle",attrs:{"color":"success"},on:{"click":function($event){_vm.showAddLdModal = true}}},[_c('TButtonRefresh',{staticClass:"float-right",attrs:{"options":{ disabled: _vm.list_fetching }},on:{"click":_vm.fetchLadingBill}}),_c('TMessage',{attrs:{"content":"Add lading bill","bold":"","alignment":"center"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-plus"}})]},proxy:true}])})],1),_vm._l((_vm.lading_bills),function(lading_bill,index){return _c('CListGroupItem',{key:`${index}${lading_bill.id}`,attrs:{"color":_vm.lading_bill_id_selected == lading_bill.id
              ? 'primary'
              : 'secondary',"to":_vm._.getAppendRouteQuery(_vm.$route, {
              lading_bill_id: lading_bill.id,
              tablb: 0,
            })}},[_c('SCardLadingBill',{attrs:{"lading_bill":lading_bill,"tiny":"","showId":""}})],1)}),(!_vm.lading_bills.length)?_c('CListGroupItem',[_c('TMessageNotFound')],1):_vm._e(),(_vm.list_fetching)?_c('CListGroupItem',[_c('TSpinner',{attrs:{"loading":_vm.list_fetching}})],1):_vm._e(),_c('SButtonLoadmore',{attrs:{"store":"warehouse.lading_bills_order"}})],2)],1)],1),(_vm.lading_bill_id_selected && _vm.lading_bills.length)?_c('CCol',{attrs:{"lg":"9"}},[_c('TTabs',{attrs:{"tabs":_vm.tabs,"activeTab":_vm.$route.query.tablb},on:{"change-tab":_vm.changeTab},scopedSlots:_vm._u([{key:"detail",fn:function(){return [_c('SCardLadingBillDetail',{attrs:{"store":"warehouse.lading_bills_order","ladingBill":_vm.lading_bill_selected}})]},proxy:true},{key:"list",fn:function(){return [(_vm.ladingBill.type_freight_range)?_c('SCardSkuLadingBill',{attrs:{"lading_bill_id":_vm.ladingBill.id}}):_vm._e()]},proxy:true},{key:"compensation",fn:function(){return [_c('SCardCompensationLadingBill',{attrs:{"compensations":_vm.ladingBill.compensations}})]},proxy:true}],null,false,2229861615)})],1):_vm._e(),_c('SModalSelectLadingBill',{attrs:{"show":_vm.showAddLdModal,"noCustomerFilter":"","default_filter":{
      'filter[customer_id]': _vm.contract.customer_id,
    }},on:{"update:show":function($event){_vm.showAddLdModal=$event},"select":_vm.addLadingBill},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('TInputCheckbox',{staticClass:"mb-1",attrs:{"checked":_vm.allowOrderChangeContract,"label":"Allow order that already have another contract to change to this contract"},on:{"update:checked":function($event){_vm.allowOrderChangeContract=$event},"change":function($event){_vm.allowOrderChangeContract = $event}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }