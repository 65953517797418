<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem
            @click="showAddLdModal = true"
            color="success"
            class="cursor-pointer d-fle"
          >
            <TButtonRefresh
              class="float-right"
              @click="fetchLadingBill"
              :options="{ disabled: list_fetching }"
            />
            <TMessage content="Add lading bill" bold alignment="center">
              <template #prefix>
                <CIcon name="cil-plus" class="mr-2" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem
            v-for="(lading_bill, index) in lading_bills"
            :key="`${index}${lading_bill.id}`"
            :color="
              lading_bill_id_selected == lading_bill.id
                ? 'primary'
                : 'secondary'
            "
            :to="
              _.getAppendRouteQuery($route, {
                lading_bill_id: lading_bill.id,
                tablb: 0,
              })
            "
          >
            <SCardLadingBill :lading_bill="lading_bill" tiny showId />
          </CListGroupItem>
          <CListGroupItem v-if="!lading_bills.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.lading_bills_order" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9" v-if="lading_bill_id_selected && lading_bills.length">
      <TTabs
        :tabs="tabs"
        @change-tab="changeTab"
        :activeTab="$route.query.tablb"
      >
        <template #detail>
          <SCardLadingBillDetail
            store="warehouse.lading_bills_order"
            :ladingBill="lading_bill_selected"
          />
        </template>
        <template #list>
          <SCardSkuLadingBill
            v-if="ladingBill.type_freight_range"
            :lading_bill_id="ladingBill.id"
          />
        </template>
        <template #compensation>
          <SCardCompensationLadingBill
            :compensations="ladingBill.compensations"
          />
        </template>
      </TTabs>
    </CCol>
    <SModalSelectLadingBill
      :show.sync="showAddLdModal"
      @select="addLadingBill"
      noCustomerFilter
      :default_filter="{
        'filter[customer_id]': contract.customer_id,
      }"
    >
      <template #prepend-content>
        <TInputCheckbox
          :checked.sync="allowOrderChangeContract"
          @change="allowOrderChangeContract = $event"
          label="Allow order that already have another contract to change to this contract"
          class="mb-1"
        />
      </template>
    </SModalSelectLadingBill>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tabs: [
        {
          key: "detail",
          name: "Lading bill detail",
          icon: "cisLibraryBooks",
        },
        {
          key: "list",
          name: "SKU list",
          icon: "cisBox",
        },
        {
          key: "compensation",
          name: "Compensation",
          icon: "cisDocument",
        },
      ],
      showAddLdModal: false,
      allowOrderChangeContract: false,
    };
  },
  created() {
    this.$store.dispatch("warehouse.freight_charge_types.fetch.if-first-time");
    this.$store.dispatch("warehouse.lading_bills_order.apply-query", {
      "filter[contract_id]": this.contract_id,
    });
  },
  watch: {
    lading_bill_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.lading_bills_order.detail.fetch", id);
          this.lading_bills.filter((x) => x.id == id);
        }
      },
    },
  },
  computed: {
    lading_bill_id_selected() {
      return this.$route.query.lading_bill_id;
    },
    lading_bill_selected() {
      return this.$store.getters["warehouse.lading_bills_order.detail"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.lading_bills_order.fetching"];
    },
    lading_bills_creating() {
      return this.$store.getters["warehouse.lading_bills_order.creating"];
    },
    ...mapGetters({
      lading_bills: "warehouse.lading_bills_order.list",
      loading: "warehouse.lading_bills_order.loading",
      detail_loading: "warehouse.lading_bills_order.detail.loading",
      ladingBill: "warehouse.lading_bills_order.detail",
      contract: "order.contracts.detail",
    }),
    contract_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      let query = this.lodash.omit({ ...this.$route.query, tablb: tab }, [
        "action",
      ]);
      this.$router.push({ query: query });
    },
    fetchLadingBill() {
      this.$store.dispatch("warehouse.lading_bills_order.fetch");
    },
    addLadingBill(ld_id) {
      this.$tomoni.warehouse.attach_ladingbill_to_contract
        .attachLadingbill({
          contract_id: this.contract_id,
          lading_bill_id: ld_id,
          allow_orders_change_contract: this.allowOrderChangeContract ? 1 : 0,
        })
        .then(() => {
          this.fetchLadingBill();
          this.$store.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          this.allowOrderChangeContract = false;
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
  },
};
</script>
